@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Mono&display=swap');

.App {
  text-align: center;
  /* background-color: #242821;
  background-color: #202C31; */
  /* background-color: #1A1F26; */
  /* background-color: #1d272b; */
  /* color: #CAD2C5; */
  font-family: 'Roboto', sans-serif;
  font-size: large;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Dark {
  background-color: #1f2629;
  color: #CAD2C5;
}

.Semi-dark-txt {
  color: #706E4F;
}

.Color-txt {
  color: rgb(70, 104, 108);
}

.Dark-txt {
  color: #1f2629;
}

.Dark-txt a {
  color: #1f2629;
  text-decoration: none;
}


.Light {
  color: #1f2629;;
  background-color: #CAD2C5;
}

.Light-txt {
  color: #CAD2C5;
}

.Light-txt a {
  color: #CAD2C5;
  text-decoration: none;
}

.Super-light-txt {
  color: #eef2eb;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-main {

}

.Logo {

}

.Mobile-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  z-index: 3;
  top: 0;
  border-bottom: 1px solid #7a7b79;
}

.Mobile-nav-links {
  display: flex;
  justify-content: center;
}


.Mobile-nav-list {
  padding-top: 2px;
  display: flex;
  list-style: none;
  text-decoration: none;
  justify-content: center;
  align-items: center;
}

.Mobile-nav-link {
  padding: 0px 15px;
}

.Navbar {
  min-height: 15vh;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  justify-content: center;
  align-items: center;
}

.Nav-links {
  display: flex;
  justify-content: left;
}

.Nav-list {
  padding-top: 2px;
  display: flex;
  list-style: none;
  text-decoration: none;
  justify-content: left;
  margin-left: -35.5px;
}
.Nav-link {
  padding-right: 20px;
  padding-left: -20px;

}
.Nav-link a:hover {
  text-decoration: underline;
}

.Logo {
  margin: 0 auto;
  margin-top: 5px;
  width: auto;
  height: 45%;
}


.Landing-container {
  /* display: grid;
  grid-template-columns: 1fr 4fr 1fr; */
  width: 100%;
  text-align: left;
}

.Mobile-parallax {
  z-index: -1;
}



.Mobile-landing {
  min-height: 88vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Mobile-text-container {
  margin-top: 15vh;
  font-size: x-large;
}

.Landing-banner {
  min-height: 100vh;
}

.Landing-image-container {

}

.Landing-image {
  width: 100%;
  height: auto;
}
.Landing-text-container {
  width: 40%;
  margin-top: 50vh;
  margin-left: 10%;
  padding: 10px;
  text-align: left;
}

.Landing-greeting {
  /* color: #52796f; */
  /* font-family: 'Roboto Mono', monospace; */
  font-size: 1.5em;
  margin: 25px 0;
}

.Landing-name {
  font-size: 5em;
  margin: 25px 0;
}

.Landing-p-1 {
  /* color: #354f52; */
  font-size: 1.5em;
  margin: 25px 0;
}

.Landing-image {
  display: block;
  width: 80%;
  height: auto;
  margin: 0 auto;
}

.Mobile-image {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  position: relative;
  z-index: 1;
}

.About-container {
  min-height: 60vh;
  color: #EAEDE8;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  text-align: left;
  position: relative;
  z-index: 2;
}

.About-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid #7a7b79;;
  border-radius: 10px;
  margin: 10px 0;
  padding: 30px;
}

.Chemistry-logo {
  width: 45px;
  height: 45px;
  display: block;
  margin: 30px auto;
}

.About-info {
  padding-right: 40px;
}

.Skills-container {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 4fr;
  border: 1px solid #1f2629;
  border-radius: 5px;
  align-items: center;
  margin: 10px 0;
}

.Skills-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Front-end {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Back-end {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; */
  display: flex;
  justify-content: start;
  width: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Other {
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  display: flex;
  justify-content: flex-start;
  width: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.Skill-badge {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 5px;
  margin: 5px 0;

}

.Skill-badge:hover {
  animation-name: item-bounce;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.Skill-name {
  font-size: small;
  text-align: center;
}

.Work-container {
  min-height: 100vh;
  color: #EAEDE8;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  text-align: left;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.Work-content {
  border-top: 1px solid #7a7b79;;
  display: grid;
  grid-template-rows: 70px 1fr;
}

.Project-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
}

.Project-card {
  border-radius: 10px;
  margin: 15px auto;
  display: grid;
  grid-template: 1fr 50px / 1fr 1fr;
  border: 1px solid #7a7b79;
  border-radius: 10px;
  min-height: 70vh;
}

.Project-image-r {
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-row: 1 / span 2;
  grid-column: 2;
  border-top-right-radius: 10px;
  /* border-bottom-right-radius: 10px; */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid #7a7b79;
  border-bottom: 1px solid #7a7b79;
}

.Project-image-l {
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-column: 1;
  grid-row: 1 / span 2;
  border-top-left-radius: 10px;
  /* border-bottom-left-radius: 10px; */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #7a7b79;
}

.Project-about-r {
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 0;
  border-left: 1px solid #7a7b79;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #7a7b79;
}

.Project-about-l {
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #7a7b79;

}

.Project-tech-r {
  /* border-top: 1px solid #1f2629; */
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 0;
  border-left: 1px solid #7a7b79;
}

.Project-tech-l {
  /* border-top: 1px solid #1f2629; */
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 0;
  /* border-right: 1px solid #7a7b79; */
}

.Project-title {
  padding: 10px 10px;
  font-size: x-large;
  font-style: italic;
}

.Project-description {
  padding: 10px 10px;
  font-size: medium;
}

.Project-links {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  z-index: 5;
}

.Project-links a {
  text-decoration: none;
}

.Project-links a:hover {
  text-decoration: underline;
  animation-name: item-bounce;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}


.P-badge {
  min-width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5px 5px 5px;
  margin: 5px;
  border: 1px solid #1f2629;
  border-radius: 3px;

}


.P-name {
  font-size: small;
  text-align: center;
}

.P-logo {
  padding: 0px 0px;
  overflow: auto;
  height: 30px;
  width: 30px;
}

.Link {
  padding: 10px;
  margin: 0px 2px;
  border: 1px solid #1f2629;
  border-radius: 3px;

}

.Link:hover {
  background-color: #1f2629;
}

.Link:hover a {
  color: #C9D2C5;
  text-decoration: underline;
}

.Link a {
  text-decoration: none;
  color: #1f2629;
  
}



.Tech-logo {
  padding: 0px 5px;
  overflow: auto;
  height: 30px;
  width: 30px;
}

.Tech-logo:hover {
  animation-name: item-bounce;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.Contact-container {
  min-height: 90vh;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  border-radius: 10px;
  text-align: left;
  position: relative;
  z-index: 0;

}

.Contact-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #7a7b79;;
  border-radius: 10px;
  margin: 20px 0;
  padding: 30px;
}

.Contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Mail-logo {
  padding: 0px 5px;
  overflow: auto;
  height: 45px;
  width: 45px;
}

.Mail-logo:hover {
  animation-name: item-bounce;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.Resume a {
  text-decoration: none;
  color: #1f2629;
}

.Resume {
  padding: 10px;
  margin: 0px 2px;
  border: 1px solid #1f2629;;
  border-radius: 3px;
}

.Resume:hover {
  background-color: #1f2629;
}

.Resume:hover a {
  color: #CAD2C5;
}


.Resume a:hover {
  text-decoration: underline;
}



.Goodbye {
  text-align: center;
}

.Socials {
  max-width: 30%;
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border: 1px solid #1f2629;
  border-radius: 5px;
}

.Socials-label {
  padding: 0px 10px;
}

.Socials-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.Italics{
  font-style: italic;
  font-size: xx-large;
}

.Footer-container {
  min-height: 10vh;
  color: #EAEDE8;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  text-align: left;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.Footer-content {
  display: flex;
  justify-content: space-between;
  font-size: small;
}

.Credits-container {
  color: #EAEDE8;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  text-align: left;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.Credits-content {
  border-top: 1px solid #7a7b79;;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.Credits:hover {
  text-decoration: underline;
}

.App-link {
  color: #61fbc2;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes item-bounce {
  0% {transform: translateY(0)}
  100% {transform: translateY(-5px)}

}

@media screen and (max-width: 950px) {

  .Work-container {
    grid-template-columns: 2% 1fr 2%;
  }
  .Project-grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .Project-card {
    margin: 20px 0;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .Project-image-r {
    border-top-right-radius: 10px;
    border-right: none;
    border-bottom: 1px solid #7a7b79;
    /* min-height: 45vh; */
  }

  .Project-image-l {
    border-top-right-radius: 10px;
    border-right: none;
    border-bottom: 1px solid #7a7b79;
    /* min-height: 45vh; */
  }

  .Project-about-r {
    margin-top: -15px;
    margin-bottom: 10px;
    padding: 2% 10px 8% 10px;
    min-height: 25vh;
  }

  .Project-about-l {
    margin-top: -15px;
    margin-bottom: 10px;
    padding: 2% 10px 8% 10px;
    min-height: 25vh;
  }

  .Project-tech-r {
    justify-content: center;
  }

  .Project-tech-l {
    justify-content: center;
  }

  .Project-links {
    margin-top: -10px;
    justify-content: center;
  }

  .Link {
    font-size: medium;
    padding: 10px;
    margin: 0px 2px;
    border: 1px solid #7a7b79;;
    border-radius: 3px;
  }

  .Link:hover {
    background-color: #CAD2C5;
  }

  .Link:hover a {
    color: #1f2629;
  }

  .About-container {
    margin-top: -7vh;
    grid-template-columns: 2% 1fr 2%;
  }

  .About-content {
    display: flex;
    flex-direction: column;
  }

  .Skills-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 0px;
  }

  .Footer-container {
    margin-top: -7vh;
    grid-template-columns: 2% 1fr 2%;
  }


  .Contact-container {
    grid-template-columns: 2% 1fr 2%;
  }

  .Socials {
    max-width: 100%;
  }

  .Tech-logo {
    padding: 1px;
  }

}

@media screen and (max-width: 800px) {
  .Mobile-navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    z-index: 3;
    top: 0;
    border-bottom: 1px solid #7a7b79;
  }
  
  .Mobile-nav-links {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  
  .Mobile-nav-list {
    padding-top: 2px;
    padding: 5px;
    display: grid;
    grid-template-columns: 22% 22% 12% 22% 22%;
    list-style: none;
    text-decoration: none;
    justify-content: center;
    align-items: center;
  }
  
  .Mobile-nav-link {
    padding: 0px 15px;
  }



  .Project-card {
    min-height: 100vh;
  }

  .Project-image-r {
    min-height: 35vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    border-bottom: 1px solid #7a7b79;
  }

  .Project-image-l {
    min-height: 35vh;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: none;
    border-bottom: 1px solid #7a7b79;

  }
  
  .Project-about-r {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 55vh;
    border: none;
  }

  .Project-about-l {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 55vh;
    border: none;
  }

  .Project-tech-r {
    justify-content: center;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-left: none;
    border-top: 1px solid #1f2629;
    height: 60px;
  }

  .Project-tech-l {
    justify-content: center;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-left: none;
    border-top: 1px solid #1f2629;
    height: 60px;
  }

  .Project-title {
    font-size: large;
  }
  .Project-description {
    margin-top: -15px;
    padding: 0 10px;
    font-size: medium;
  }
}
